<template>
  <div>
    <ca-page-header :title="$t('app.messages_and_notifications')" icon="mdi-android-messages" />

    <v-row justify="center">
      <v-col lg="8">
        <v-card v-for="message in messages" :id="message.id" :key="message.id" class="mb-10">
          <v-card-title>{{ message.title }}</v-card-title>
          <v-card-subtitle>{{ message.datetime }}</v-card-subtitle>
          <v-card-text v-html="message.message" />
        </v-card>

        <v-pagination v-show="totalPages > 1" v-model="page" :length="totalPages" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CaPageHeader from "../../components/PageHeader";

export default {
  metaInfo() {
    return {
      title: this.$t("titles.notifications")
    };
  },
  components: { CaPageHeader },
  data() {
    return {
      messages: [],
      page: 1,
      totalPages: 0,
      totalMessages: 0
    };
  },
  watch: {
    page(page) {
      this.loadMessages(page);
    }
  },
  mounted() {
    this.markMessagesAsReadAndLoadMessages();
  },
  methods: {
    markMessagesAsReadAndLoadMessages() {
      this.$store.dispatch("app/markUserMessagesAsRead").then(() => {
        this.$store.dispatch("auth/loadUserData");

        this.loadMessages(1);
      });
    },
    loadMessages(page) {
      this.axios.get("/messages?page=" + page).then(response => {
        this.messages = response.data.data;
        this.totalPages = response.data.meta.last_page;
      });
    }
  }
};
</script>
